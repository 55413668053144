import { NumericFormat } from "react-number-format";

interface Props {
  value?: string | number | null;
}

export const Real = ({ value }: Props) => {
  return (
    <>
      <NumericFormat
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale
        prefix="R$ "
        value={value}
        displayType="text"
      />
    </>
  );
};
