import { CSSProperties, PropsWithChildren } from "react";
import * as S from "./styles";

interface Props {
  style?: CSSProperties;
}

export const FooterToolbar = ({
  style,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div style={{ marginTop: 12, width: "100%", ...style }}>
      <S.Container>{children}</S.Container>
    </div>
  );
};
